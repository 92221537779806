import React from 'react'


interface Props {
  strokeColor?: string;
}


function Explore({ strokeColor }: Props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1153_75)">
        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"

          stroke={`${strokeColor ? strokeColor : '#585B63'}`}




          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M16.5 7.5L10.5 10.5L7.5 16.5L13.5 13.5L16.5 7.5Z"
          stroke={`${strokeColor ? strokeColor : '#585B63'}`}




          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1153_75">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default Explore
