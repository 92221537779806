import { Link } from 'react-router-dom';
import { WordmarkLogo } from 'mastodon/components/logo';

import { Button } from 'mastodon/components/button';
import { SearchBar } from './SearchBar';
import { HeaderUserInfo } from './HeaderUserInfo';
import { useState } from 'react';
import { useFormContext } from './FormContext';


import House from "../../../../images/dashboard/icons/House";
import ExploreIcon from "../../../../images/dashboard/icons/Explore";
import TestimonialIcon from "../../../../images/dashboard/icons/Testimonials";
import CommunityIcon from "../../../../images/dashboard/icons/Community";
import PrayerIcon from "../../../../images/dashboard/icons/Prayer";
import CalendarIcon from "../../../../images/dashboard/icons/Calendar";
import ConnetIcon from "../../../../images/dashboard/icons/Connect";
import GiveIcon from "../../../../images/dashboard/icons/Give";
import LegacyIcon from "../../../../images/dashboard/icons/Legacy";
import ServeIcon from "../../../../images/dashboard/icons/Serve";
import MoreIcon from "../../../../images/dashboard/icons/More";
import PrefrencesIcon from "../../../../images/dashboard/icons/Prefrences";


const menu_items = [
    {
        id: 1,
        title: 'Home',
        uri: '/home',
        subMenu: null,
        icon: <House />,
    },
    {
        id: 2,
        title: 'Explore',
        uri: null,
        subMenu: [],
        icon: <ExploreIcon />,

    },
    {
        id: 3,
        title: 'Testimonials',
        uri: null,
        subMenu: [],
        icon: <TestimonialIcon />,

    },
    {
        id: 4,
        title: 'Community',
        uri: null,
        subMenu: [],
        icon: <CommunityIcon />,
    },
    {
        id: 5,
        title: 'Prayer Room',
        uri: null,
        subMenu: [],
        icon: <PrayerIcon />,

    },
    {
        id: 6,
        title: 'Events',
        uri: null,
        subMenu: [],
        icon: <CalendarIcon />,

    },
    {
        id: 7,
        title: 'Connect',
        uri: null,
        subMenu: [],
        icon: <ConnetIcon />,

    },
    {
        id: 8,
        title: 'Give',
        uri: null,
        subMenu: [],
        icon: <GiveIcon />,

    },
    {
        id: 9,
        title: 'W.F. Kumuyi Legacy',
        uri: null,
        subMenu: [],
        icon: <LegacyIcon />,

    },
    {
        id: 10,
        title: 'Serve',
        uri: null,
        subMenu: [],
        icon: <ServeIcon />,

    },
    {
        id: 11,
        title: 'More',
        uri: null,
        subMenu: [],
        icon: <MoreIcon />,

    },
    {
        id: 11,
        title: 'Preferences',
        uri: "/",
        subMenu: null,
        icon: <PrefrencesIcon />,

    },

]


export const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { handleFormSubmit } = useFormContext();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (

        <div className="relative">
            {/* <!-- Desktop Header --> */}
            <div className='header-container'>
                <div>
                    <div className='header-logo-section'>
                        <Link to='/' className=''>
                            <WordmarkLogo />
                        </Link>
                        <SearchBar />
                    </div>
                    <div className='header-profile-section'>
                        {/* <div className='lg:block hidden'>
                            <Button
                                onClick={handleFormSubmit}
                                className="!w-[127px] !h-[40px] rounded-[12px] bg-[#0967AF]"
                            >
                                Post
                            </Button>
                        </div> */}
                        <HeaderUserInfo />
                    </div>
                </div>
            </div>



            {/* <!-- Mobile Header --> */}
            <div className="landing-header-mobile md:hidden h-[76px]">
                {/* <WordmarkLogo /> */}

                <Link to='/' className=''>
                    <WordmarkLogo />
                </Link>
                <button onClick={toggleMenu} className="text-gray-900">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>
            </div>

            {/* <!-- Mobile Navigation Menu --> */}
            <div
                className={`md:hidden fixed inset-0 z-50 bg-white transform transition-transform duration-300 ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
                    }`}
            >
                <div className='w-[100%] flex justify-end px-[28px] py-[24px]'>

                    <button
                        onClick={closeMenu}
                        className="absolute top-4 right-4 text-gray-900"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="landing-header-mobile-menu min-h-[100vh] items-start  overflow-y-auto">
                    <ul>
                        {menu_items.map((menu_item) => (
                            menu_item.uri ? (
                                <li key={menu_item.id} className={`dashboard_menu_item ${menu_item.uri === location.pathname && "dashboard_menu_item_active"}`}>
                                    <Link to={menu_item.uri}>
                                        {menu_item.icon}

                                        {menu_item.title}</Link>

                                </li>
                            ) : (
                                <li key={menu_item.id} className={`dashboard_menu_dropdown pointer`}
                                >
                                    {menu_item.icon}

                                    {menu_item.title}

                                </li>
                            )

                        ))}
                    </ul>
                    <Button onClick={() => { console.log("") }} className="px-8 py-3 text-sm font-medium mt-6 !w-[100%]">
                        Post
                    </Button>

                </div>
            </div>
        </div>




    );
};

