import React from 'react'


interface Props {
    strokeColor?: string;
}


function Prayer({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_31)">
                <path d="M13.2919 1.50001C13.5696 1.49918 13.8402 1.58795 14.0635 1.75312C14.2867 1.9183 14.4508 2.15107 14.5312 2.41688L18 13.875L19.9003 15.7753L15.6816 19.8066L12.8747 16.9997C12.3146 16.4375 12.0001 15.6764 12 14.8828V2.78907C12.0007 2.44693 12.1372 2.11906 12.3794 1.87739C12.6216 1.63573 12.9497 1.5 13.2919 1.50001Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M15.6853 19.8104L17.4056 21.5307C17.5463 21.6713 17.7369 21.7502 17.9358 21.7502C18.1346 21.7502 18.3253 21.6713 18.4659 21.5307L21.5306 18.4689C21.6712 18.3282 21.7501 18.1375 21.7501 17.9387C21.7501 17.7399 21.6712 17.5492 21.5306 17.4085L19.8103 15.6882" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.1216 17.0034L8.3147 19.8103L4.1897 15.6853L6.00001 13.875L9.46876 2.41688C9.54923 2.15107 9.71328 1.9183 9.93655 1.75312C10.1598 1.58795 10.4304 1.49918 10.7081 1.50001C11.0508 1.50001 11.3794 1.63611 11.6216 1.87839C11.8639 2.12066 12 2.44925 12 2.79188V14.8856C11.9991 15.68 11.6832 16.4417 11.1216 17.0034Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.18969 15.6853L2.46937 17.4056C2.32883 17.5463 2.24988 17.7369 2.24988 17.9358C2.24988 18.1346 2.32883 18.3253 2.46937 18.4659L5.53125 21.5306C5.67189 21.6712 5.86258 21.7501 6.0614 21.7501C6.26023 21.7501 6.45092 21.6712 6.59156 21.5306L8.31187 19.8103" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1153_31">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Prayer
