import React from 'react'


interface Props {
    strokeColor?: string;
}


function Connect({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_1672)">
                <path d="M12 13.125C12.6213 13.125 13.125 12.6213 13.125 12C13.125 11.3787 12.6213 10.875 12 10.875C11.3787 10.875 10.875 11.3787 10.875 12C10.875 12.6213 11.3787 13.125 12 13.125Z" fill="#585B63" />
                <path d="M7.875 13.125C8.49632 13.125 9 12.6213 9 12C9 11.3787 8.49632 10.875 7.875 10.875C7.25368 10.875 6.75 11.3787 6.75 12C6.75 12.6213 7.25368 13.125 7.875 13.125Z" fill="#585B63" />
                <path d="M16.125 13.125C16.7463 13.125 17.25 12.6213 17.25 12C17.25 11.3787 16.7463 10.875 16.125 10.875C15.5037 10.875 15 11.3787 15 12C15 12.6213 15.5037 13.125 16.125 13.125Z" fill="#585B63" />
                <path d="M7.49344 19.7917C9.38394 20.8858 11.6078 21.2551 13.7505 20.8307C15.8931 20.4062 17.8083 19.217 19.1389 17.4848C20.4696 15.7526 21.1248 13.5956 20.9825 11.4159C20.8403 9.2363 19.9102 7.18277 18.3656 5.63825C16.8211 4.09372 14.7676 3.16361 12.5879 3.02132C10.4083 2.87904 8.25126 3.53429 6.51904 4.86492C4.78683 6.19555 3.59765 8.11072 3.17321 10.2534C2.74877 12.396 3.11805 14.6199 4.21219 16.5104L3.03938 20.012C2.99531 20.1441 2.98891 20.286 3.02091 20.4215C3.0529 20.5571 3.12202 20.6811 3.22052 20.7796C3.31903 20.8781 3.44301 20.9472 3.57859 20.9792C3.71417 21.0112 3.85598 21.0048 3.98813 20.9607L7.49344 19.7917Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1153_1672">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Connect
