import { useFormContext } from './FormContext';
import { useState } from 'react';
import { getAccessToken } from '../../../initial_state';

import { Button } from 'mastodon/components/button';
import UserPic from '../../../../images/dashboard/elizabeth.jpeg';
import ImageIcon from '../../../../images/dashboard/icons/ImageIcon.svg';
import VideoIcon from '../../../../images/dashboard/icons/VideoIcon.svg';
import ChartBarIcon from '../../../../images/dashboard/icons/ChartBarIcon.svg';
import MicrophoneIcon from '../../../../images/dashboard/icons/MicrophoneIcon.svg';
import EmojiIcon from '../../../../images/dashboard/icons/EmojiIcon.svg';
import TranslateIcon from '../../../../images/dashboard/icons/TranslateIcon.svg';

const icons = [
  {
    iconImage: ImageIcon,
    name: 'image'
  },
  {
    iconImage: VideoIcon,
    name: 'video'
  },
  {
    iconImage: ChartBarIcon,
    name: 'chart'
  },
  {
    iconImage: MicrophoneIcon,
    name: 'microphone'
  },
  {
    iconImage: EmojiIcon,
    name: 'emoji'
  }
]



export const NewPost = () => {
  
  const { formData, updateBtn, charCount, setFormData, setFilesData, setCharCount, handleFormSubmit, handleEditFormSubmit } = useFormContext();

  const handleIconClick = (type) => {
    if (type === 'image') {
    }
  };

  const getCSRFToken = () => {
    return document.querySelector('meta[name="csrf-token"]').content
  }
  let imgIds = [];
  const handleImageUpload = async (e) => {
    
    for (const [i, file] of Array.from(e.target.files).entries()) {
      const data = new FormData();
      console.log("file: ", file);
      data.append("file", file, file.name);

      const response = await fetch('/api/v2/media', {
        method: 'POST',
        headers: { 
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${getAccessToken()}`,
        },
        body: data
      });
      const res = await response.json();
      imgIds.push(res.id);
    }
    setFilesData(imgIds);
  }

  const handleChange = (e) => {
    setFormData(e.target.value);
    setCharCount(e.target.value.length);
  };
  return (
    <div className='new-post-container'>
      <div className='new-post-upper'>
        <img src={UserPic} alt='Bucket Icon' className='h-9 w-9 rounded-full object-cover' />
        <div className='new-post-inputs'>
          <select name="" id="" className='new-post-drop-down'>
            <option value="public">Public</option>
            <option value="prvate">Private</option>
          </select>
          <input
            type="text"
            value={formData}
            onChange={(e) => {handleChange(e)}}
            className="new-post-text"
            placeholder='What’s on your mind Elizabeth?'
          />
        </div>
      </div>
      <div className="new-post-icons">
     
      <div className="image-upload">
        <label for="file-input" className='icon-lable'>
        {icons.map((icon, index) => (
          <img 
            src={icon.iconImage} 
            alt={icon.name}
            key={index} 
            onClick={() => {handleIconClick(icon.name)}}
          />
        ))}
        </label>

        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
          className='setting-text event-text'
          id='file-input'
          name='file-upload-input'
          onClick={(e) => e.stopPropagation()}
          />
      </div>
        <div className='seperator'></div>
        <div className='translate-button'>
          <img src={TranslateIcon} alt="" />
          <p>English</p>
        </div>
        <div className='lg:block hidden post-submit-div'>
          <p>{charCount}</p>
          {updateBtn 
            ? 
              <Button
                onClick={handleEditFormSubmit}
                className="update-submit"
              >
                Update
              </Button>
            : 
              <Button
                onClick={handleFormSubmit}
                className="post-submit"
              >
                Post
              </Button> 
          }
            
        </div>
      </div>
    </div >
  );
};