import { Button } from 'mastodon/components/button';
import { FormattedMessage } from 'react-intl';
import event1 from '../../../../images/landing/event-1.png';
import event2 from '../../../../images/landing/event-2.png';
import event3 from '../../../../images/landing/event-3.png';
import calendar from '../../../../images/landing/CalendarCheck.svg';
import { Link } from 'react-router-dom';

const events_data = [
  {
    title: 'Light of Hope Community Outreach',
    date: 'Aug 02, 2024',
    img: event2,
    uri: '#',
  },
  {
    title: 'Light of Hope Community Outreach',
    date: 'Aug 02, 2024',
    img: event1,
    uri: '#',
  },
  {
    title: 'Light of Hope Community Outreach',
    date: 'Aug 02, 2024',
    img: event3,
    uri: '#',
  },
];

interface Props {}
export const UpcomingEvents: React.FC<Props> = () => {
  return (
    <div className='landing-events'>
      <h1>Upcoming Events</h1>
      {/* Events List */}
      <div>
        {events_data.map((event, index) => (
          <div className='event-card'>
            <img src={event.img} alt='event-1' />
            <div>
              <span>
                <img src={calendar} alt='Calendar Icon' />
                {event.date}
              </span>
              <h3>{event.title}</h3>
              <Link to={event.uri}>Learn more</Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
