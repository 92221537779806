import { Button } from 'mastodon/components/button';

interface Props {
  title: string;
  description: string;
  image: string;
  btnText: string;
}
export const Donation: React.FC<Props> = ({
  title,
  description,
  image,
  btnText
}) => {
  const handleNavigation = () => {
    window.location.href = '#';
  };
  return (
    <div className="landing-donation">
      <div className='donation-left-section'>
        <img src={image} alt='donation Banner' />
      </div>
      <div className='donation-right-section'>
        <div>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <div className="donation-form">
            <div className="donation-form-button">
              <div className='donation-once'>Give once</div>
              <div className='donation-monthly'>Give monthly</div>
            </div>
            <div className="donation-amount-selection-row-1">
              <input type="radio" id='ten' name="donation-amount" />
              <label htmlFor="ten">$10.00</label>
              <input type="radio" id='twenty' name="donation-amount" />
              <label htmlFor="twenty">$20.00</label>
              <input type="radio" id='fifty' name="donation-amount" />
              <label htmlFor="fifty">$50.00</label>
              <input type="radio" id='seventy-five' name="donation-amount" />
              <label htmlFor="seventy-five">$75.00</label>
              <input type="radio" id='hundred' name="donation-amount" />
              <label htmlFor="hundred">$100.00</label>
              <input type="text" id='amount' placeholder='Enter amount' className='border border-[#CBD3D8] rounded-[8px] px-[10px] text-center' />
            </div>
           
          </div>
          <Button
            onClick={handleNavigation}
            className='banner-donation-button !w-[100%]'
          >
            {btnText}
          </Button>
        </div>
      </div>
    </div>
  );
};
