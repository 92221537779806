import React from "react";
import { DashboardSidebar } from "./DashboardSidebar";
import { Trending } from './Trending';
import Groups from "./Groups";
import PeopleToFollow from "./PeopleToFollow";

interface Props {
    children: React.ReactNode
}
export const DashboardLayout: React.FC<Props> = ({ children }) => {
    return (
        <div className='dashboard-layout-container'>
            <div>
                <DashboardSidebar />
                {
                    children
                }
                <div className="layout-right-sidebar">
                    <Trending />
                    <Groups />
                    <PeopleToFollow />
                </div>
            </div>

        </div>
    );
};