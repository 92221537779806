import { Button } from 'mastodon/components/button';

interface Props {
  subTitle: string;
  title: string;
  description: string;
  image: string;
  btnText: string;
  classNames?: string;
  btnUri?: string | null;
}
export const Volunteer: React.FC<Props> = ({
  subTitle,
  title,
  description,
  image,
  btnText,
  classNames,
  btnUri,
}) => {
  const handleNavigation = () => {
    if (btnUri) {
      window.location.href = btnUri;
    }
  };
  return (
    <div className={`landing-volunteer ${classNames}`}>
      <div className='volunteer-left-section'>
        <img src={image} alt='Volunteer Banner' />
      </div>
      <div className='volunteer-right-section'>
        <div>
          <p className='section-title'>{subTitle}</p>
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <Button
            onClick={handleNavigation}
            className={'banner-secondary-button button-w-fit'}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </div>
  );
};
