import { Button } from 'mastodon/components/button';
import { FormattedMessage } from 'react-intl';

interface Props {}
export const Banner: React.FC<Props> = () => {
  const handleNavigation = () => {
    window.location.href = '/auth/sign_up'; 
  };

  return (
    <div className='landing-banner'>
      <div>
        <h1>We are reaching every community for Christ.</h1>
        <p>
          K21CO is a global Gospel community outreach and social media platform.
          We unite diverse communities through faith, fostering connection,
          inspiration, and support while bridging language and cultural
          barriers. Together, we share messages of hope and faith that empower
          individuals and communities worldwide to thrive."
        </p>
      </div>
      <div>
        <Button onClick={handleNavigation} className={'banner-primary-button'}>
          JOIN OUR COMMUNITY
        </Button>
      </div>
    </div>
  );
};
