export function formatDuration(timestamp: string | Date): string {
  const timestampDate: Date = typeof timestamp === 'string' ? new Date(timestamp) : timestamp;

  const now: Date = new Date();

  const diffMs: number = now.getTime() - timestampDate.getTime();

  const msInMinute = 1000 * 60;
  const msInHour = msInMinute * 60;
  const msInDay = msInHour * 24;
  const msInWeek = msInDay * 7;
  const msInMonth = msInDay * 30; 

  const diffWeeks: number = Math.floor(diffMs / msInWeek);
  const diffDays: number = Math.floor(diffMs / msInDay);
  const diffHours: number = Math.floor(diffMs / msInHour);
  const diffMinutes: number = Math.floor(diffMs / msInMinute);
  if (diffWeeks > 0) {
      return `${diffWeeks}w`;
  } else if (diffDays > 0) {
      return `${diffDays}d`;
  } else if (diffHours > 0) {
      return `${diffHours}h`;
  } else if (diffMinutes > 0) {
      return `${diffMinutes}m`;
  } else {
      return "Just now";
  }
}