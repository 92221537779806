import React from 'react'
import searchIcon from '../../../../images/landing/search-icon.svg';


interface Props { }
export const SearchBar: React.FC<Props> = () => {
    return (
        <div className='searchbar'>
            <img src={searchIcon} alt='Search Icon' className="" />
            <input
                type="text"
                className=""
                placeholder='Search post, groups, profile'
            />
        </div>
    );
};