import { Button } from 'mastodon/components/button';
import VolunteerBanner from '../../../../images/landing/volunteer-banner.png';

interface Props {
  data: {
    membership: number;
    volunteers: number;
    donations: number;
  };
}
export const VolunteerStats: React.FC<Props> = ({ data }) => {
  return (
    <div className={`landing-volunteer-stats`}>
      <div className='volunteer-stat'>
        <h3>{data.membership}</h3>
        <p>MEMBERSHIP</p>
      </div>
      <div className='volunteer-stat'>
        <h3>{data.volunteers}</h3>
        <p>VOLUNTEERS</p>
      </div>
      <div className='volunteer-stat'>
        <h3>{data.donations}</h3>
        <p>DONATIONS</p>
      </div>
    </div>
  );
};
