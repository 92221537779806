import { FormattedMessage } from 'react-intl';

import { Link } from 'react-router-dom';
import { WordmarkLogo } from 'mastodon/components/logo';

import { Button } from 'mastodon/components/button';
import bucketIcon from '../../../../images/landing/bucket-icon.svg';
import searchIcon from '../../../../images/landing/search-icon.svg';
import { useState } from 'react';

const header_menu_items = [
  {
    title: 'Home',
    uri: '#',
  },
  {
    title: 'About Us',
    uri: '#',
  },
  {
    title: 'Events',
    uri: '#',
  },
  {
    title: 'Shop',
    uri: '#',
  },
  {
    title: 'Contact Us',
    uri: '#',
  },
];

interface Props { }
export const Header: React.FC<Props> = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setIsMenuOpen(false);
  };
  return (
    <div className="relative">
      {/* <!-- Desktop Header --> */}
      <div className='landing-header-desktop'>
        <div>
          <div className='landing-header-left'>
            <Link to='/' className=''>
              <WordmarkLogo />
            </Link>
            <ul>
              {header_menu_items.map((header_menu_item) => (
                <li key={header_menu_item.title}>{header_menu_item.title}</li>
              ))}
            </ul>
          </div>
          <div className='landing-header-right'>
            <img src={bucketIcon} alt='Bucket Icon' />
            <img src={searchIcon} alt='Search Icon' />
            <a href='auth/sign_in' className=''>
              Login
            </a>
            <Button
              onClick={() => { }}
              className={'banner-secondary-button button-w-fit'}
            >
              Donate
            </Button>
          </div>
        </div>
      </div>



      {/* <!-- Mobile Header --> */}
      <div className="landing-header-mobile">
        {/* <WordmarkLogo /> */}

        <Link to='/' className=''>
          <WordmarkLogo />
        </Link>
        <button onClick={toggleMenu} className="text-gray-900">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>

      {/* <!-- Mobile Navigation Menu --> */}
      <div
        className={`lg:hidden fixed inset-0 z-50 bg-white transform transition-transform duration-300 ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        <div className='w-[100%] flex justify-end px-[28px] py-[24px]'>

          <button
            onClick={closeMenu}
            className="absolute top-4 right-4 text-gray-900"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="landing-header-mobile-menu">
          <ul>
            {header_menu_items.map((header_menu_item) => (
              <li key={header_menu_item.title}>{header_menu_item.title}</li>
            ))}
          </ul>
          <Button onClick={() => { }} className="px-8 py-3 text-sm font-medium mt-6 !w-[100%]">
            Donate
          </Button>
          <a href="auth/sign_in" className="w-[100%] py-[10px] px-[30px] rounded-[6px] bg-[#f2f8fc] text-sm font-light leading-[21.82px] text-gray-900">
            Login
          </a>
        </div>
      </div>
    </div>
  );
};
