import { useState, useRef } from 'react';
import { VideoPlayer } from '../components/VideoPlyer';

import thumbnail1 from '../../../../images/landing/video-thumbnail-1.png';
import thumbnail2 from '../../../../images/landing/video-thumbnail-2.png';
import thumbnail3 from '../../../../images/landing/video-thumbnail-3.png';

const community_videos = [
  {
    id: 1,
    url: '',
    thumbnail: thumbnail1,
  },
  {
    id: 2,
    url: '',
    thumbnail: thumbnail2,
  },
  {
    id: 3,
    url: '',
    thumbnail: thumbnail3,
  },
];

interface Props {}
export const CommunityVideo: React.FC<Props> = () => {
  const [selectedVideo, setSelectedVideo] = useState<{
    id: number;
    url: string;
    thumbnail: string;
  }>({
    id: 2,
    url: '',
    thumbnail: thumbnail2,
  });
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <div className='landing-community-video'>
      <div>
        <div className='landing-community-video-header'>
          <h3>Building communities. Changing lives.</h3>
          <p>
            Watch the remarkable story of how K21CO is building a sustainable
            community and changing lives.
          </p>
        </div>
        <VideoPlayer data={selectedVideo} />

        <div className='landing-community-video-list'>
          {community_videos.map((video, index) => (
            <img
              src={video.thumbnail}
              alt='Video Thumbnail'
              className={`${video.id === selectedVideo.id ? 'list-icon-active' : 'list-icon'}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
