import Marquee from 'react-fast-marquee';
import { Button } from 'mastodon/components/button';
import { CustomMarquee } from './CustomMarquee';
import community1 from '../../../../images/landing/community-1.png';
import community2 from '../../../../images/landing/communiy-2.png';
import community3 from '../../../../images/landing/communiy-3.png';
import community4 from '../../../../images/landing/communiy-4.png';
import community5 from '../../../../images/landing/communiy-5.png';
import community6 from '../../../../images/landing/communiy-6.png';
import community7 from '../../../../images/landing/communiy-7.png';
import community8 from '../../../../images/landing/communiy-8.png';
import community9 from '../../../../images/landing/communiy-8.png';
import community10 from '../../../../images/landing/communiy-10.png';
import community11 from '../../../../images/landing/communiy-11.png';
import community12 from '../../../../images/landing/communiy-12.png';
import community13 from '../../../../images/landing/communiy-13.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const community = [
  {
    image: community1,
    alt: 'community-1',
  },
  {
    image: community2,
    alt: 'community-2',
  },
  {
    image: community3,
    alt: 'community-3',
  },
  {
    image: community4,
    alt: 'community-4',
  },
  {
    image: community5,
    alt: 'community-5',
  },
  {
    image: community6,
    alt: 'community-6',
  },
  {
    image: community7,
    alt: 'community-7',
  },
  {
    image: community8,
    alt: 'community-8',
  },
  {
    image: community9,
    alt: 'community-9',
  },
  {
    image: community10,
    alt: 'community-10',
  },
  {
    image: community11,
    alt: 'community-11',
  },
  {
    image: community12,
    alt: 'community-12',
  },
  {
    image: community13,
    alt: 'community-13',
  },
];

interface Props {}
export const OurMission: React.FC<Props> = () => {
  const handleNavigation = () => {
    window.location.href = '/auth/sign_up';
  };
  return (
    <div className='landing-mission'>
      <div>
        <div>
          <p>OUR MISSION</p>
          <h4>
            K21CO exists to spread the saving message of the Gospel and God’s
            love across the globe. Our mission is to use technology to bring
            eternal hope to people from diverse cultures and backgrounds.
          </h4>
        </div>
        <div className='marquee-container'>
          <CustomMarquee data={community} />
        </div>
        <Button
          onClick={handleNavigation}
          className={'banner-primary-button button-w-fit'}
        >
          JOIN OUR COMMUNITY
        </Button>
      </div>
    </div>
  );
};
