import { useState, useRef } from 'react';
import playIcon from '../../../../images/landing/play-icon.svg';


interface Props {
  data: {
    url: string;
    thumbnail: string;
  };
}
export const VideoPlayer: React.FC<Props> = ({ data }) => {
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    setPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };
  return (
    <div className='landing-community-video-player'>
      <div className='video-container'>
        {!playing ? (
          <div className='thumbnail-container' onClick={handlePlay}>
            <img
              src={data.thumbnail}
              alt='Video Thumbnail'
              className='thumbnail'
            />
            <div className='play-button'>
              <img src={playIcon} alt='Video Play' />
            </div>
          </div>
        ) : (
          <video
            ref={videoRef}
            src={data.url}
            controls
            className={`video-player ${playing ? 'visible' : 'hidden'}`}
          />
        )}
      </div>
    </div>
  );
};
