import React from 'react'
import { Link } from 'react-router-dom'
import UserInfo from './UserInfo'
import { Button } from 'mastodon/components/button'

import Group1 from "../../../../images/dashboard/group1.png";

const dumm_groups = [
    {
        id: 1,
        name: 'Grace Seekers',
        email: '32.4k members',
        image: Group1,
    },
    {
        id: 2,
        name: 'Grace Seekers',
        email: '32.4k members',
        image: Group1,
    },
    {
        id: 3,
        name: 'Grace Seekers',
        email: '32.4k members',
        image: Group1,
    },
    {
        id: 4,
        name: 'Grace Seekers',
        email: '32.4k members',
        image: Group1,
    },
]



const Groups = () => {
    return (
        <div className='card-wrapper'>
            <div className='card-header'>
                <h3>Groups to join</h3>
                <Link to={'#'}>See more</Link>
            </div>
            {
                dumm_groups.map((group, index) => (
                    <div className='card-item' key={index}>
                        <UserInfo userData={group} className='header-user-info' />
                        <Button
                            onClick={() => { }}
                            className="!w-[72px] !h-[32px] rounded-[12px] bg-[#0967AF]"
                        >
                            Join
                        </Button>
                    </div>

                ))
            }

        </div>
    )
}

export default Groups