import PostAuthor from './PostAuthor'
import PostDetail from './PostDetail'

interface Props {
    data: {
        account: {
            acct: string;
            avatar: string;
            avatar_static: string;
            bot: boolean;
            created_at: string;
            discoverable: null | string;
            display_name: string;
            emojis: [] | string[];
            fields: [] | string[];
            followers_count: number;
            following_count: number;
            group: boolean;
            header: string;
            header_static: string;
            hide_collections: null | string;
            id: string;
            indexable: boolean;
            last_status_at: string;
            locked: boolean;
            noindex: boolean;
            note: string;
            roles: [] | string[];
            statuses_count: number;
            uri: string;
            url: string;
            username: string;
          },
          application: {
            name: string;
            website: null | string;
          } | null;
          bookmarked: boolean;
          card: null | string;
          content: string;
          created_at: string;
          updated_at: string;
          edited_at: string;
          emojis: [] | string[];
          favourited: boolean;
          favourites_count: number;
          filtered: [] | string[];
          id: string;
          in_reply_to_account_id: null | string;
          in_reply_to_id: null | string;
          language: string;
          media_attachments: Array<{
            blurhash: string;
            description: null | string;
            id: string;
            meta: {
              original: {
                width: number;
                height: number;
                size: string;
                aspect: number;
              };
              small: {
                width: number;
                height: number;
                size: string;
                aspect: number;
              };
            };
            preview_remote_url: null | string;
            preview_url: string;
            remote_url: null | string;
            text_url: null | string;
            type: string;
            url: string;
          }>;
          mentions: [] | string[];
          muted: boolean;
          pinned: boolean;
          poll: null | string;
          reblog: null | string;
          reblogged: boolean;
          reblogs_count: number;
          replies_count: number;
          sensitive: boolean;
          spoiler_text: string;
          tags: [] | string[];
          uri: string;
          url: string;
          visibility: string;
    }
}


const Post = ({ data }: Props) => {
    return (
        <div className='post'>
            <PostAuthor userData={data.account} postedAt={data.updated_at} />
            <PostDetail postData={data} />
        </div>
    )
}

export default Post
