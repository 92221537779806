import UserPic from '../../../../images/dashboard/elizabeth.jpeg';

interface Props { }
export const HeaderUserInfo: React.FC<Props> = () => {
    return (
        <div className='header-user-info'>
            <img src={UserPic} alt='Bucket Icon' className='h-9 w-9 rounded-full object-cover' />
            <div className="flex flex-col">
                <h3 className='text-black'>Elizabeth Olsen</h3>
                <p className='text-[#8A8A8A]'>@theghospelsinger</p>
            </div>
        </div>
    );
};