import React from 'react'


interface Props {
    userData: {
        username: string;
        avatar: string;
    };
    className: "post-user-info" | "header-user-info";
}


const UserInfo = ({ userData, className }: Props) => {
    return (
        <div className={`${className}`}>
            <img src={userData.avatar} alt='Bucket Icon' />
            <div >
                <h3>{userData.username}</h3>
            </div>
        </div>
    )
}

export default UserInfo
