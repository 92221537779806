import { Header } from './components/Header';
import { DashboardLayout } from './components/Layout';
import Post from './components/post';
import { NewPost } from './components/NewPost.jsx';
import { FormProvider } from './components/FormContext';
import { useEffect, useState } from 'react';
import { getAccessToken } from '../../initial_state';

const getCSRFToken = (): string => {
  const element: HTMLElement | null = document.querySelector(
    'meta[name="csrf-token"]',
  );
  if (element instanceof HTMLMetaElement) {
    return element.content;
  }
  return '';
};

export const DashboardPage = () => {
  const [postsList, setPostsList] = useState([]);
  const [page, setPage] = useState(1);
  console.log("🚀 ~ DashboardPage ~ page:", page)
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchPosts();
  }, [postsList]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollHeight - (scrollTop + clientHeight) < 100 && !loading) {
        if (hasMore) {
          setPage(prevPage => prevPage + 1);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, hasMore]);

  const stripHtmlTags = (htmlString :string) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };

  const fetchPosts = async () => {
    const posts: any = [];
    try {
      const csrfToken = getCSRFToken();
      const accessToken = getAccessToken();
      const response = await fetch('api/v1/timelines/home', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const result = await response.json();
      result.forEach((post: any) => {
        let tempPost = {
          account: {
            acct: post.account.acct,
            avatar: post.account.avatar,
            avatar_static: post.account.avatar_static,
            bot: post.account.bot,
            created_at: post.account.created_at,
            discoverable: post.account.discoverable,
            display_name: post.account.display_name,
            emojis: post.account.emojis,
            fields: post.account.fields,
            followers_count: post.account.followers_count,
            following_count: post.account.following_count,
            group: post.account.group,
            header: post.account.header,
            header_static: post.account.header_static,
            hide_collections: post.account.hide_collections,
            id: post.account.id,
            indexable: post.account.indexable,
            last_status_at: post.account.last_status_at,
            locked: post.account.locked,
            noindex: post.account.noindex,
            note: post.account.note,
            roles: post.account.roles,
            statuses_count: post.account.statuses_count,
            uri: post.account.uri,
            url: post.account.url,
            username: post.account.username,
          },
          application: {
            name: post.application?.name,
            website: post.application?.website
          },
          bookmarked: post.bookmarked,
          card: post.card,
          content: stripHtmlTags(post.content),
          updated_at: post.updated_at,
          created_at: post.created_at,
          edited_at: post.edited_at,
          emojis: post.emojis,
          favourited: post.favourited,
          favourites_count: post.favourites_count,
          filtered: post.filtered,
          id: post.id,
          in_reply_to_account_id: post.in_reply_to_account_id,
          in_reply_to_id: post.in_reply_to_id,
          language: post.language,
          media_attachments: post.media_attachments,
          mentions: post.mentions,
          muted: post.muted,
          pinned: post.pinned,
          poll: post.poll,
          reblog: post.reblog,
          reblogged: post.reblogged,
          reblogs_count: post.reblogs_count,
          replies_count: post.replies_count,
          sensitive: post.sensitive,
          spoiler_text: post.spoiler_text,
          tags: post.tags,
          uri: post.uri,
          url: post.url,
          visibility: post.visibility,
        };
        posts.push(tempPost);
      });
      setPostsList(posts);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <FormProvider fetchPosts={fetchPosts}>
      <div className='bg-[#f4f5f6]'>
        <Header />
        <DashboardLayout>
          <div className='posts-container'>
            <NewPost />
            {postsList.map((post: any) => (
              <Post data={post} key={post?.id} />
            ))}
          </div>
        </DashboardLayout>
      </div>
    </FormProvider>
  );
};
