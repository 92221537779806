import React from 'react'


interface Props {
    strokeColor?: string;
}

function Testimonials({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_95)">
                <path d="M15.75 6C15.75 3.92893 14.0711 2.25 12 2.25C9.92893 2.25 8.25 3.92893 8.25 6V12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12V6Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 18.75V22.5" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M18.75 12C18.75 13.7902 18.0388 15.5071 16.773 16.773C15.5071 18.0388 13.7902 18.75 12 18.75C10.2098 18.75 8.4929 18.0388 7.22703 16.773C5.96116 15.5071 5.25 13.7902 5.25 12" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1153_95">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Testimonials
