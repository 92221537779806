import React from 'react'


interface Props {
    strokeColor?: string;
}


function Community({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_54)">
                <path d="M7.875 15C10.5674 15 12.75 12.8174 12.75 10.125C12.75 7.43261 10.5674 5.25 7.875 5.25C5.18261 5.25 3 7.43261 3 10.125C3 12.8174 5.18261 15 7.875 15Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0.959106 18.75C1.70825 17.5982 2.73323 16.6517 3.94098 15.9965C5.14873 15.3412 6.50101 14.998 7.87504 14.998C9.24908 14.998 10.6014 15.3412 11.8091 15.9965C13.0169 16.6517 14.0418 17.5982 14.791 18.75" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.125 15C17.499 14.9992 18.8513 15.3418 20.0592 15.9967C21.267 16.6517 22.292 17.5981 23.0409 18.75" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M14.3147 5.59687C14.9816 5.3309 15.6989 5.2155 16.4156 5.25893C17.1322 5.30235 17.8304 5.50352 18.4602 5.84806C19.0901 6.19261 19.6361 6.67202 20.0592 7.25204C20.4823 7.83206 20.7721 8.49838 20.9078 9.20338C21.0435 9.90837 21.0219 10.6346 20.8444 11.3303C20.6669 12.026 20.3379 12.6738 19.881 13.2276C19.4241 13.7814 18.8505 14.2274 18.2012 14.5338C17.5519 14.8402 16.843 14.9994 16.125 15" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1153_54">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Community
