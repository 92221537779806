import React from 'react'
import UserInfo from '../UserInfo'
import Clock from '../../../../../images/dashboard/icons/Clock.svg';
import {formatDuration} from '../../../../utils/time_converter';

interface Props {
    userData: {
        username: string;
        avatar: string;
    },
    postedAt: string;
}

const PostAuthor = ({ userData, postedAt }: Props) => {
    return (
        <div className='post-author'>
            <UserInfo userData={userData} className='post-user-info' />
            <p>
                <img src={Clock} alt="clock-icon" />
                {formatDuration(postedAt)}
            </p>
        </div>
    )
}

export default PostAuthor
