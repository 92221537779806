import React from 'react'


interface Props {
    strokeColor?: string;
}


function Calendar({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_1631)">
                <path d="M19.5 3.75H4.5C4.08579 3.75 3.75 4.08579 3.75 4.5V19.5C3.75 19.9142 4.08579 20.25 4.5 20.25H19.5C19.9142 20.25 20.25 19.9142 20.25 19.5V4.5C20.25 4.08579 19.9142 3.75 19.5 3.75Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M16.5 2.25V5.25" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.5 2.25V5.25" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.75 8.25H20.25" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12 13.5C12.6213 13.5 13.125 12.9963 13.125 12.375C13.125 11.7537 12.6213 11.25 12 11.25C11.3787 11.25 10.875 11.7537 10.875 12.375C10.875 12.9963 11.3787 13.5 12 13.5Z" fill="#585B63" />
                <path d="M16.125 13.5C16.7463 13.5 17.25 12.9963 17.25 12.375C17.25 11.7537 16.7463 11.25 16.125 11.25C15.5037 11.25 15 11.7537 15 12.375C15 12.9963 15.5037 13.5 16.125 13.5Z" fill="#585B63" />
                <path d="M7.875 17.25C8.49632 17.25 9 16.7463 9 16.125C9 15.5037 8.49632 15 7.875 15C7.25368 15 6.75 15.5037 6.75 16.125C6.75 16.7463 7.25368 17.25 7.875 17.25Z" fill="#585B63" />
                <path d="M12 17.25C12.6213 17.25 13.125 16.7463 13.125 16.125C13.125 15.5037 12.6213 15 12 15C11.3787 15 10.875 15.5037 10.875 16.125C10.875 16.7463 11.3787 17.25 12 17.25Z" fill="#585B63" />
                <path d="M16.125 17.25C16.7463 17.25 17.25 16.7463 17.25 16.125C17.25 15.5037 16.7463 15 16.125 15C15.5037 15 15 15.5037 15 16.125C15 16.7463 15.5037 17.25 16.125 17.25Z" fill="#585B63" />
            </g>
            <defs>
                <clipPath id="clip0_1153_1631">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Calendar
