
import { Link } from "react-router-dom";
import DotsThree from "../../../../images/dashboard/icons/DotsThree.svg";

export const Trending = () => {

  const trending_items = [
    {
      title: '#SundayService',
      count: '3,340 posts in the past 2 days'
    },
    {
      title: '#SundayService',
      count: '3,340 posts in the past 2 days'
    },
    {
      title: '#SundayService',
      count: '3,340 posts in the past 2 days'
    },
    {
      title: '#SundayService',
      count: '3,340 posts in the past 2 days'
    }
  ]

  return (
    <div className='card-wrapper'>
      <div className='card-header'>
        <h3>Trending Now</h3>
        <Link to={'#'}>See more</Link>
      </div>
      {
        trending_items.map((item, index) => (
          <div className='trending-item' key={index}>
            <div>
              <h3>{item.title}</h3>
              <p>{item.count}</p>
            </div>
            <button>
              <img src={DotsThree} alt="" />
            </button>
          </div>

        ))
      }

    </div>
  );
};