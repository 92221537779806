import React from 'react'

const Arrow = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1258_80)">
                <path d="M11.375 5.25L7 9.625L2.625 5.25" stroke="#585B63" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1258_80">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Arrow;
