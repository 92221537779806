import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'mastodon/components/button';
import { FormattedMessage } from 'react-intl';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import UserImage from '../../../../images/landing/user-1.png';
import leftIcon from '../../../../images/landing/carousel-left.svg';
import rightIcon from '../../../../images/landing/carousel-right.svg';

const feedbacks = [
  {
    title: 'K21CO Impact',
    subTitle:
      'Hear impactful feedback from our community members round the world',
    description:
      'Living in a remote area, I had little access to spiritual resources. Through K21CO’s digital outreach, I found a wealth of inspirational content and connected with a vibrant, supportive community. The messages of the Gospel and God’s love deeply resonated with me, bringing newfound hope and purpose. Today, I share my faith with others in my village, using the same technology that transformed my life.',
    user: {
      userName: 'Mark Tech',
      jobTitle: 'Community member',
      UserImage: UserImage,
    },
  },
  {
    title: 'K21CO Impact',
    subTitle:
      'Hear impactful feedback from our community members round the world',
    description:
      'Living in a remote area, I had little access to spiritual resources. Through K21CO’s digital outreach, I found a wealth of inspirational content and connected with a vibrant, supportive community. The messages of the Gospel and God’s love deeply resonated with me, bringing newfound hope and purpose. Today, I share my faith with others in my village, using the same technology that transformed my life.',
    user: {
      userName: 'Mark Tech',
      jobTitle: 'Community member',
      UserImage: UserImage,
    },
  },
  {
    title: 'K21CO Impact',
    subTitle:
      'Hear impactful feedback from our community members round the world',
    description:
      'Living in a remote area, I had little access to spiritual resources. Through K21CO’s digital outreach, I found a wealth of inspirational content and connected with a vibrant, supportive community. The messages of the Gospel and God’s love deeply resonated with me, bringing newfound hope and purpose. Today, I share my faith with others in my village, using the same technology that transformed my life.',
    user: {
      userName: 'Mark Tech',
      jobTitle: 'Community member',
      UserImage: UserImage,
    },
  },
];

const NextIcon = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='47.5'
      y='47.5'
      width='47'
      height='47'
      rx='23.5'
      transform='rotate(180 47.5 47.5)'
      stroke='#0967AF'
    />
    <path
      d='M21 16.5L28.5 24L21 31.5'
      stroke='#232323'
      stroke-width='1.5'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

const PrevIcon = () => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect x='0.5' y='0.5' width='47' height='47' rx='23.5' stroke='#0967AF' />
    <g clip-path='url(#clip0_230_146)'>
      <path
        d='M27 31.5L19.5 24L27 16.5'
        stroke='#232323'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_230_146'>
        <rect
          width='24'
          height='24'
          fill='white'
          transform='translate(12 12)'
        />
      </clipPath>
    </defs>
  </svg>
);

interface Props {}
export const Feedback: React.FC<Props> = () => {
  return (
    <div className='landing-feedback'>
      <div>
        <Carousel
          nextIcon={<NextIcon />}
          prevIcon={<PrevIcon />}
          prevLabel=''
          nextLabel=''
          interval={null}
        >
          {feedbacks.map((feedback, index) => (
            <Carousel.Item>
              <div className='carousel-custom-item' key={index}>
                <div className='carousel-custom-item-header'>
                  <h3>{feedback.title}</h3>
                  <p>{feedback.subTitle}</p>
                </div>
                <div className='carousel-custom-item-content'>
                  <p>{feedback.description}</p>
                  <div>
                    <img src={feedback.user.UserImage} alt='User Image' />
                    <div>
                      <h5>{feedback.user.userName}</h5>
                      <p>{feedback.user.jobTitle}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
