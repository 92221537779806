import React, { useState, useEffect, useRef } from 'react';
import { getAccessToken } from '../../../../initial_state';
import { useFormContext } from '../FormContext';

import ChatIcon from "../../../../../images/dashboard/icons/ChatCircle.svg";
import HeartIcon from "../../../../../images/dashboard/icons/Heart.svg";
// import HeartFilledIcon from "../../../../../images/dashboard/icons/HeartFilledIcon.svg";
import RepeatIcon from "../../../../../images/dashboard/icons/Repeat.svg";
import RepeatOnIcon from "../../../../../images/dashboard/icons/RepeatOnIcon.svg";
import BookmarkIcon from "../../../../../images/dashboard/icons/BookmarkSimple.svg";
import BookmarkCheckIcon from "../../../../../images/dashboard/icons/BookmarkCheckIcon.svg";
import ThreeDots from "../../../../../images/dashboard/icons/DotsThree.svg";
import ForwardIcon from "../../../../../images/dashboard/icons/ArrowBendUpRight.svg";


interface Props {
	postData: any;
}


const PostActions = ({ postData }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const modalRef = useRef<HTMLDivElement>(null);
	const { 
		setFormData, fetchPosts, setUpdateBtn, setPost, 
		handlePostLike, handlePostBoost, handlePostBookmark,
		handlePostUnLike, handlePostUnBoost, handlePostUnBookmark,
		handlePostShare
	} = useFormContext();

	useEffect(() => {
			const handleClickOutside = (event: MouseEvent) => {
				if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
					setIsModalOpen(false);
				}
			};
	
			document.addEventListener('mousedown', handleClickOutside);
			return () => document.removeEventListener('mousedown', handleClickOutside);
	}, []);

	const toggleModal = () => {
		setIsModalOpen(prevState => !prevState);
	};

	const getCSRFToken = (): string => {
		const element: HTMLElement | null = document.querySelector(
			'meta[name="csrf-token"]',
		);
		if (element instanceof HTMLMetaElement) {
			return element.content;
		}
		return '';
	};

	const handleDelete = async (e :any) => {
		e.preventDefault();
			const accessToken = getAccessToken();
			const response = await fetch(`api/v1/statuses/${postData.id}`, {
			method: 'DELETE',
			headers: { 
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-CSRF-Token': getCSRFToken(),
				Authorization: `Bearer ${accessToken}`,
			}
		});
		const result = await response.json();
		if(response.status === 200) fetchPosts()
	}

	const handleEdit = async (e :any) => {
		e.preventDefault();
		const accessToken = getAccessToken();
			const response = await fetch(`api/v1/statuses/${postData.id}/source`, {
			method: 'GET',
			headers: { 
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'X-CSRF-Token': getCSRFToken(),
				Authorization: `Bearer ${accessToken}`,
			}
		});
		const result = await response.json();
		setFormData(result.text)
		setUpdateBtn(true);
		setPost(postData);
	}

	const handleLike = (e :any) => {
		postData.favourites_count > 0 ? handlePostUnLike(postData) : handlePostLike(postData);
	}

	const handleBoost = (e :any) => {
		postData.reblogs_count > 0 ? handlePostUnBoost(postData) : handlePostBoost(postData);
	}

	const handleBookmark = (e :any) => {
		postData.bookmarked ? handlePostUnBookmark(postData) : handlePostBookmark(postData);
	}

	const handleShare = (e :any) => {
		handlePostShare(postData)
	}

	return (
			<div className='post-actions'>
					<div>
							<p>
									<img src={ChatIcon} alt="Comment" />
									{postData.replies_count}
							</p>
							<p>
									<img src={ postData.favourites_count > 0 ? HeartIcon : HeartIcon} alt="Likes" onClick={((e) => {handleLike(e)})} />
									{postData.favourites_count}
							</p>
							<p>
									<img src={ postData.reblogs_count > 0 ? RepeatOnIcon : RepeatIcon} alt="boost"  onClick={((e) => {handleBoost(e)})}/>
									{postData.reblogs_count}
							</p>
							<p>
									<img src={postData.bookmarked ? BookmarkCheckIcon : BookmarkIcon} alt="Saved" onClick={((e) => {handleBookmark(e)})}/>
							</p>
					</div>
					<div>
							<button>
									<img src={ForwardIcon} alt="" onClick={((e) => {handleShare(e)})} />
							</button>
							<button onClick={toggleModal}>
									<img src={ThreeDots} alt="" />
							</button>
							<div className={isModalOpen ? 'post-modal' : 'post-modal-hide'} ref={modalRef}>
									<a 
									href="" 
									className={isModalOpen ? 'post-modal-a' : 'post-modal-a-hide'} 
									onClick={((e) => {handleEdit(e)})}
									>
										Edit
									</a>
									<a href="" 
											className={isModalOpen ? 'post-modal-a' : 'post-modal-a-hide'} 
											onClick={((e) => {handleDelete(e)})}
									>
										Delete
									</a>
							</div>
					</div>
			</div>
	)
}

export default PostActions
