

import { Link, useLocation } from "react-router-dom";
import House from "../../../../images/dashboard/icons/House";
import ExploreIcon from "../../../../images/dashboard/icons/Explore";
import TestimonialIcon from "../../../../images/dashboard/icons/Testimonials";
import CommunityIcon from "../../../../images/dashboard/icons/Community";
import PrayerIcon from "../../../../images/dashboard/icons/Prayer";
import CalendarIcon from "../../../../images/dashboard/icons/Calendar";
import ConnetIcon from "../../../../images/dashboard/icons/Connect";
import GiveIcon from "../../../../images/dashboard/icons/Give";
import LegacyIcon from "../../../../images/dashboard/icons/Legacy";
import ServeIcon from "../../../../images/dashboard/icons/Serve";
import MoreIcon from "../../../../images/dashboard/icons/More";
import PrefrencesIcon from "../../../../images/dashboard/icons/Prefrences";
import Arrow from "../../../../images/dashboard/icons/Arrow";
import React, { useState } from "react";

const menu_items = [
  {
    id: 1,
    title: 'Home',
    uri: '/home',
    subMenu: null,
    icon: House,
  },
  {
    id: 2,
    title: 'Explore',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Daily News',
        uri: '/daily-news',
      },
      {
        id: 2,
        title: '10 mins with W.F Kumuyi',
        uri: '/mins-with',
      },
      {
        id: 3,
        title: 'Recent Testimonies',
        uri: '/recent-testimonies',
      },
      {
        id: 4,
        title: 'Newsletter',
        uri: '/newsletter',
      },
      {
        id: 5,
        title: 'News & Inspiration',
        uri: '/news-inspiration',
      },
    ],
    icon: ExploreIcon,

  },
  {
    id: 3,
    title: 'Testimonials',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Share you story',
        uri: '/share-your-story',
      },
      {
        id: 2,
        title: 'Watch & be inspired',
        uri: '/watch',
      },
    ],
    icon: TestimonialIcon

  },
  {
    id: 4,
    title: 'Community',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Forums & Discussions',
        uri: '/forums-discussions',
      },
      {
        id: 2,
        title: 'Groups',
        uri: '/groups',
      },
      {
        id: 3,
        title: 'Posts',
        uri: '/posts',
      },
      {
        id: 4,
        title: 'Blog',
        uri: '/blog',
      },
      {
        id: 5,
        title: 'Lifestyle & Growth',
        uri: '/lifestyle',
      },
      {
        id: 6,
        title: 'Music & Worship',
        uri: '/music',
      },
      {
        id: 7,
        title: 'Spoken Words',
        uri: '/spoken-words',
      },
    ],
    icon: CommunityIcon
  },
  {
    id: 5,
    title: 'Prayer Room',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Prayer Requests',
        uri: '/prayer-requests',
      },
      {
        id: 2,
        title: 'Prayer Groups',
        uri: '/prayer-groups',
      },
      {
        id: 3,
        title: 'Daily Devotions',
        uri: '/daily-devotions',
      },
      {
        id: 4,
        title: 'Live Prayer',
        uri: '/live-prayer',
      },
    ],
    icon: PrayerIcon

  },
  {
    id: 6,
    title: 'Events',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Featured Event',
        uri: '/featured-event',
      },
      {
        id: 2,
        title: 'Event Calnedar',
        uri: '/event-calendar',
      },
      {
        id: 3,
        title: 'Recordings/Replays',
        uri: '/recordings',
      },
      {
        id: 3,
        title: 'Highlights & Feedback',
        uri: '/highlights',
      },
    ],
    icon: CalendarIcon,

  },
  {
    id: 7,
    title: 'Connect',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Messaging',
        uri: '/messaging',
      },
      {
        id: 2,
        title: 'Voice & Video calls',
        uri: '/voice-video-calls',
      },
      {
        id: 3,
        title: 'Group Chats',
        uri: '/group-chats',
      },
      {
        id: 4,
        title: 'Live Streaming',
        uri: '/live-streaming',
      },
    ],
    icon: ConnetIcon,

  },
  {
    id: 8,
    title: 'Give',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Donate',
        uri: '/donate',
      },
      {
        id: 2,
        title: 'Fundraise',
        uri: '/fundraise',
      },
      {
        id: 3,
        title: 'Tithes & Offerings',
        uri: '/tithes',
      },
      {
        id: 4,
        title: 'Recurring & Giving',
        uri: '/recurring',
      },
      {
        id: 5,
        title: 'Matching',
        uri: '/matchins',
      },
    ],
    icon: GiveIcon,

  },
  {
    id: 9,
    title: 'W.F. Kumuyi Legacy',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Sermons & Teaching',
        uri: '/sermons',
      },
      {
        id: 2,
        title: 'Books & Publication',
        uri: '/books',
      },
      {
        id: 3,
        title: 'The Library',
        uri: '/library',
      },
      {
        id: 4,
        title: 'Exploits & Impact',
        uri: '/exploits',
      },
      {
        id: 5,
        title: 'Bible Studies & Resources',
        uri: '/bible',
      },
      {
        id: 6,
        title: 'Kumuyi Answers',
        uri: '/kumuyi-answers',
      },
    ],
    icon: LegacyIcon,

  },
  {
    id: 10,
    title: 'Serve',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Volunteer',
        uri: '/volunteer',
      },
      {
        id: 2,
        title: 'Outreach',
        uri: '/outreach',
      },
      {
        id: 3,
        title: 'Sponsor',
        uri: '/sponser',
      },
    ],
    icon: ServeIcon,

  },
  {
    id: 11,
    title: 'More',
    uri: null,
    subMenu: [
      {
        id: 1,
        title: 'Notifications',
        uri: '/notificatins',
      },
      {
        id: 2,
        title: 'Private Mentions',
        uri: '/private-mentions',
      },
      {
        id: 3,
        title: 'Bookmarks',
        uri: '/bookmarks',
      },
    ],
    icon: MoreIcon,

  },
  {
    id: 11,
    title: 'Preferences',
    uri: "/prefrences",
    subMenu: null,
    icon: PrefrencesIcon,

  },

]

interface Props { }
export const DashboardSidebar: React.FC<Props> = () => {
  const location = useLocation();

  // ? Local States
  const [selectedMenu, setSelectedMenu] = useState<number | null>(null);


  // ? Local Functions
  const menuDropdownHandler = (selectedMenuId: number) => {
    setSelectedMenu(prevSelected => prevSelected === selectedMenuId ? null : selectedMenuId);
  }

  const _handle_Logout = async () => {
    const token: any = document.querySelector('meta[name="csrf-token"]')?.content;
    try {
      const response = await fetch('/auth/sign_out', {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`, // Add your token here
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
      });

      if (response.ok) {
        let url = window.location.host == 'localhost:3000' ? "http://" + `${window.location.host}` : "https://" + `${window.location.host}`
        window.location.replace(url);
      } else {
        console.error('Logout failed');
        // Handle errors (e.g., show an error message)
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };


  return (
    <div className='dashboard-sidebar'>
      <ul>
        {
          menu_items.map((menu_item, index) => (

            menu_item.subMenu === null ? (
              <li key={menu_item.id} className={`dashboard_menu_item ${menu_item.uri === location.pathname ? "dashboard_menu_item_active" : 'dashboard_menu_item_not_active'}`}>
                <Link to={menu_item.uri}>
                  {React.createElement(menu_item.icon, { strokeColor: menu_item.uri === location.pathname ? '#0967AF' : '' })}

                  {menu_item.title}</Link>

              </li>
            ) : (
              <>
                <li key={menu_item.id} className={`dashboard_menu_dropdown cursor-pointer ${selectedMenu === menu_item.id ? '!font-[600] !text-black' : ''}`}
                  onClick={() => menuDropdownHandler(menu_item.id)}
                >
                  <div>
                    <span>
                      {React.createElement(menu_item.icon, { strokeColor: selectedMenu === menu_item.id ? 'black' : '' })}


                      {menu_item.title}
                    </span>
                    <span className={`transition-transform duration-500 ${selectedMenu === menu_item.id ? 'rotate-180' : ''}`}>
                      <Arrow />
                    </span>
                  </div>
                </li>
                <div className={`ps-[36px] pe-[10px]`}>
                  <ul
                    className={`transition-transform duration-500 ${selectedMenu === menu_item.id ? "expand-dropdown" : "collapse-dropdown"}`}
                  >
                    {
                      menu_item.subMenu.map((subMenu) => (
                        <li key={subMenu.id} className={`dashboard_menu_item ${subMenu.uri === location.pathname ? "dashboard_menu_item_active" : 'dashboard_menu_item_not_active'} ${selectedMenu === menu_item.id ? "block" : "hidden"}`}>
                          <Link to={subMenu.uri}>
                            {subMenu.title}</Link>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </>

            )


          ))
        }
        < li className={`dashboard_menu_dropdown pointer cursor-pointer`}
          onClick={() => _handle_Logout()}
        >
          Logout
        </li>

      </ul>
    </div >
  );
};