import { FormattedMessage } from 'react-intl';
import { Header } from './components/Header';
import { Banner } from './components/Banner';
import { About } from './components/About';
import { OurMission } from './components/OurMission';
import { Volunteer } from './components/Volunteer';
import { Donation } from './components/Donation';
import { VolunteerStats } from './components/VolunteerStats';
import { UpcomingEvents } from './components/UpcomingEvents';
import VolunteerBanner from '../../../images/landing/volunteer-banner.png';
import PrayerRoomBanner from '../../../images/landing/prayer-room-banner.png';
import DonationBanner from '../../../images/landing/donation-banner.png';
import { Footer } from './components/Footer';
import { CommunityVideo } from './components/CommunityVideo';
import { Feedback } from './components/Feedback';

interface Props { }
export const LandingPage: React.FC<Props> = () => {
  return (
    <div>
      <Header />
      <div className='landing-container'>
        <Banner />
        <About />
        <OurMission />
        <Volunteer
          subTitle='VOLUNTEER'
          title='Join Us to make a Difference in Your Community.'
          description='Volunteer your skills to help build and grow our community across
      the world. Whether you have a few hours a week or just a single
      day to spare, your time and effort can make a significant
      difference.'
          image={VolunteerBanner}
          btnText='BECOME A VOLUNTEER'
        />
        <VolunteerStats
          data={{
            membership: 8523,
            volunteers: 101,
            donations: 134,
          }}
        />
        <Volunteer
          subTitle='PRAYER ROOM'
          title='Non stop prayer across the world.'
          description='Gain access to creative prayer space that enables a chain of unbroken day and night prayer, bringing friends, communities and churches together to seek the presence of God.'
          image={PrayerRoomBanner}
          btnText='JOIN A PRAYER ROOM'
          classNames={'row-reverse'}
          btnUri={'/auth/sign_up'}
        />
        <Donation
          title='Support Our Mission'
          description='By donating, you help provide essential resources and support to those in need within our community. Together, we can spread love, hope, and faith.'
          image={DonationBanner}
          btnText='DONATE NOW'
        />
        <CommunityVideo />
        <Feedback />
        <UpcomingEvents />
      </div>
      <Footer />
    </div>
  );
};
