import React from 'react'


interface Props {
    strokeColor?: string;
}


function Serve({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_1739)">
                <path d="M12 7.09034L10.8919 5.98315C10.1839 5.27529 9.28189 4.79325 8.29995 4.59797C7.31801 4.40269 6.30022 4.50294 5.37526 4.88605C4.4503 5.26917 3.65969 5.91793 3.10341 6.75032C2.54713 7.58272 2.25014 8.56136 2.25 9.56253C2.25 15.75 12 21 12 21C12 21 21.75 15.75 21.75 9.56253C21.7499 8.56136 21.4529 7.58272 20.8966 6.75032C20.3403 5.91793 19.5497 5.26917 18.6247 4.88605C17.6998 4.50294 16.682 4.40269 15.7 4.59797C14.7181 4.79325 13.8161 5.27529 13.1081 5.98315L10.5 8.59034L13.3294 11.4207L11.25 13.5" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1153_1739">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Serve
