import React, { createContext, useState, useContext } from 'react';
import { getAccessToken } from '../../../initial_state';


const FormContext = createContext({
  fetchPosts: () => {},
  handleFormSubmit: () => {},
  handleEditFormSubmit: () => {},
  handlePostLike: (data) => {},
  handlePostBoost: (data) => {},
  handlePostBookmark: (data) => {},
  handlePostUnLike: (data) => {},
  handlePostUnBoost: (data) => {},
  handlePostUnBookmark: (data) => {},
  handlePostShare: (data) => {},

  setFormData: (data) => {},
  setUpdateBtn: (status) => {},
  setPost: (data) => {},
  setCharCount: (data) => {},
});

export const FormProvider = ({ children, fetchPosts }) => {
  const [formData, setFormData] = useState('');
  const [filesData, setFilesData] = useState();
  const [post, setPost] = useState();
  const [charCount, setCharCount] = useState(0);
  const [updateBtn, setUpdateBtn] = useState(false);
  

  const getCSRFToken = () => {
    return document.querySelector('meta[name="csrf-token"]').content
  }

  const handleFormSubmit = async () => {
    filesData
    const accessToken = getAccessToken();
    try {
      const response = await fetch('/api/v1/statuses', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          "status": formData,
          "in_reply_to_id":null,
          "media_ids": filesData,
          "sensitive":false,
          "spoiler_text":"",
          "visibility":"public",
          "poll":null,
          "language":"en"
        }),
      });
      const result = await response.json();
      if(response.status == 200){
        fetchPosts()
        setFormData('')
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleEditFormSubmit = async () => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${post.id}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,

        },
        body: JSON.stringify({
          "status": formData,
          "in_reply_to_id": post.in_reply_to_id,
          "media_ids": post.media_attachments.map(attachment => attachment.id),
          "media_attributes": post.media_attachments.map(attachment => ({id: attachment.id, description: attachment.description})),
          "sensitive": post.sensitive,
          "spoiler_text": post.spoiler_text,
          "visibility": post.visibility,
          "poll": post.poll,
          "language": post.language,
        }),
      });
      const result = await response.json();
      if(response.status == 200){
        fetchPosts()
        setFormData('')
        setUpdateBtn(false)
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  const handlePostLike = async (postData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${postData.id}/favourite`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ }),
      });
      const result = await response.json();
      if(response.status == 200){
        fetchPosts()
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  const handlePostBoost = async (postData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${postData.id}/reblog`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ }),
      });
      const result = await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }
  const handlePostBookmark = async (postData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${postData.id}/bookmark`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ }),
      });
      const result = await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  const handlePostUnLike = async (postData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${postData.id}/unfavourite`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ }),
      });
      const result = await response.json();
      if(response.status == 200){
        fetchPosts()
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }
  const handlePostUnBoost = async (postData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${postData.id}/unreblog`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ }),
      });
      const result = await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }
  const handlePostUnBookmark = async (postData) => {
    const accessToken = getAccessToken();
    try {
      const response = await fetch(`/api/v1/statuses/${postData.id}/unbookmark`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': getCSRFToken(),
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ }),
      });
      const result = await response.json();
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  }

  const handlePostShare = async (postData) => {
    if (navigator.share) {
      try {
        await navigator.share({
          url: postData.url,
        });
      } catch (error) {
        console.error('Share failed:', error);
      }
    } else {
      console.log('Share not supported on this browser.');
    }
  }


  return (
    <FormContext.Provider value={{ 
        formData, updateBtn, fetchPosts, post, charCount,
        setFormData, setFilesData, setUpdateBtn, setPost, setCharCount,
        handleFormSubmit, handleEditFormSubmit, handlePostLike, handlePostBoost,
        handlePostBookmark, handlePostUnLike, handlePostUnBoost, handlePostUnBookmark,
        handlePostShare
      }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
