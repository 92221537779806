import { Button } from 'mastodon/components/button';
import { FormattedMessage } from 'react-intl';
import landingAbout from '../../../../images/landing/landing-about.svg';

interface Props {}
export const About: React.FC<Props> = () => {
  const handleNavigation = () => {
    window.location.href = '/auth/sign_up';
  };

  return (
    <div className='landing-about'>
      <div>
        <img src={landingAbout} alt='About image' />
        <div>
          <p>ABOUT K21CO</p>
          <div>
            <h3>
              A global community fostering spiritual connections and growth.
            </h3>
            <p>
              A dynamic gospel outreach community and innovative social media
              platform founded by Dr. W.F. Kumuyi. Our mandate is to urgently
              spread the Good News across the globe. We welcome users from all
              countries, providing a diverse and inclusive space for global
              engagement. By joining K21CO, you become part of a movement that
              fosters meaningful connections, celebrates faith, and empowers
              individuals on their spiritual journeys. Experience a vibrant
              community where you can share, learn, and grow together.
            </p>
            <div>
              <Button
                onClick={handleNavigation}
                className={'banner-primary-button'}
              >
                JOIN US
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
