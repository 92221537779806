import React from 'react'
import { Link } from 'react-router-dom'
import UserInfo from './UserInfo'
import { Button } from 'mastodon/components/button'

import People1 from "../../../../images/dashboard/post1user.png";

const dumm_peoples = [
    {
        id: 1,
        name: 'Cherish',
        email: '@thecherishgrl',
        image: People1,
    },

]


const PeopleToFollow = () => {
    return (
        <div className='card-wrapper'>
            <div className='card-header'>
                <h3>People to follow</h3>
                <Link to={'#'}>See more</Link>
            </div>
            {
                dumm_peoples.map((people, index) => (
                    <div className='card-item' key={index}>
                        <UserInfo userData={people} className='header-user-info' />
                        <Button
                            onClick={() => { }}
                            className="!w-[72px] !h-[32px] rounded-[12px] bg-[#0967AF]"
                        >
                            Follow
                        </Button>
                    </div>

                ))
            }

        </div>
    )
}

export default PeopleToFollow

