import React from 'react'



interface Props {
    strokeColor?: string;
}


function Prefrences({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_635_5408)">
                <path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M3.884 16.6959C3.4696 15.9821 3.15212 15.2163 2.93994 14.4187L4.51307 12.4499C4.49525 12.149 4.49525 11.8472 4.51307 11.5462L2.94088 9.57744C3.1527 8.7797 3.46953 8.01363 3.88307 7.29932L6.38713 7.01807C6.58709 6.79278 6.80028 6.57959 7.02557 6.37963L7.30682 3.8765C8.0201 3.46494 8.78492 3.14999 9.58119 2.93994L11.5499 4.51307C11.8509 4.49525 12.1527 4.49525 12.4537 4.51307L14.4224 2.94088C15.2202 3.1527 15.9863 3.46953 16.7006 3.88307L16.9818 6.38713C17.2071 6.58709 17.4203 6.80028 17.6203 7.02557L20.1234 7.30682C20.5378 8.0206 20.8553 8.78639 21.0674 9.584L19.4943 11.5528C19.5121 11.8537 19.5121 12.1555 19.4943 12.4565L21.0665 14.4253C20.8562 15.2228 20.5409 15.9888 20.129 16.7034L17.6249 16.9846C17.425 17.2099 17.2118 17.4231 16.9865 17.6231L16.7053 20.1262C15.9915 20.5406 15.2257 20.8581 14.4281 21.0703L12.4593 19.4971C12.1583 19.5149 11.8566 19.5149 11.5556 19.4971L9.58682 21.0693C8.78931 20.859 8.02325 20.5437 7.30869 20.1318L7.02744 17.6278C6.80215 17.4278 6.58897 17.2146 6.389 16.9893L3.884 16.6959Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_635_5408">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Prefrences
