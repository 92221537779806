import Ticker from 'react-ticker';
import community3 from '../../../../images/landing/communiy-3.png';
import community2 from '../../../../images/landing/communiy-2.png';
import { useCallback } from 'react';
import Marquee from 'react-marquee-slider';
import styled from 'styled-components';
import times from 'lodash/times';

interface Props {
  data: {
    image: string;
    alt: string;
  }[];
}
export const CustomMarquee: React.FC<Props> = ({ data }) => {
  const CommunityImages = useCallback(
    ({
      community,
    }: {
      community: {
        image: string;
        alt: string;
      }[];
    }) => (
      <div style={{ display: 'flex', overflowX: 'auto' }}>
        {community.map((item, index: any) => {
          return (
            <img
              key={`${index}-${item.alt}`}
              src={item.image}
              alt={item.alt}
              style={{ marginRight: '10px' }}
            />
          );
        })}
        ;
      </div>
    ),
    [],
  );

  return (
    <div className=''>
      <Ticker>{({ index }) => <CommunityImages community={data} />}</Ticker>
      {/* <¸¸¸CommunityImages community={data} /> */}
    </div>
  );
};

{
  /* {({ index }) => (
  <>
    <div className='img-style'>
      <h1>This is the Headline of element #{index}!</h1>
      <img src={community3} alt='' />
    </div>

    <div className='img-style'>
      <h1>This is the Headline of element #{index}!</h1>
      <img src={community2} alt='' />
    </div>
  </>
)} */
}

// {return data.map(() => {
//   ({ index }) => (
//     <>
//       <div className='img-style'>
//         <h1>This is the Headline of element #{index}!</h1>
//         <img src={community3} alt='' />
//       </div>

//       <div className='img-style'>
//         <h1>This is the Headline of element #{index}!</h1>
//         <img src={community2} alt='' />
//       </div>
//     </>
//   );
// })}
