import React from 'react'

interface Props {
    strokeColor?: string;
}

function Give({ strokeColor }: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1153_1768)">
                <path d="M4.5 19.5H1.5C1.30109 19.5 1.11032 19.421 0.96967 19.2803C0.829018 19.1397 0.75 18.9489 0.75 18.75V15C0.75 14.8011 0.829018 14.6103 0.96967 14.4697C1.11032 14.329 1.30109 14.25 1.5 14.25H4.5" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10.5 15H13.5L19.7812 13.5553C20.0122 13.492 20.2546 13.4829 20.4897 13.5285C20.7247 13.5741 20.9461 13.6734 21.1365 13.8185C21.327 13.9636 21.4814 14.1507 21.5878 14.3652C21.6942 14.5797 21.7497 14.8159 21.75 15.0553C21.7501 15.3444 21.6697 15.6279 21.5176 15.8738C21.3656 16.1197 21.148 16.3184 20.8894 16.4475L17.25 18L11.25 19.5H4.5V14.25L6.84375 11.9063C7.05324 11.6975 7.30182 11.5321 7.5753 11.4195C7.84877 11.3069 8.14175 11.2493 8.4375 11.25H13.125C13.6223 11.25 14.0992 11.4476 14.4508 11.7992C14.8025 12.1508 15 12.6277 15 13.125C15 13.6223 14.8025 14.0992 14.4508 14.4508C14.0992 14.8025 13.6223 15 13.125 15H10.5Z" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9.06844 11.25C8.15625 10.0988 7.5 8.86501 7.5 7.50001C7.5 5.46657 9.15656 3.75001 11.1994 3.75001C11.9295 3.74144 12.6455 3.951 13.2558 4.35184C13.8661 4.75269 14.3428 5.32657 14.625 6.00001C14.9072 5.32657 15.3839 4.75269 15.9942 4.35184C16.6045 3.951 17.3205 3.74144 18.0506 3.75001C20.0934 3.75001 21.75 5.46657 21.75 7.50001C21.75 10.2403 19.1081 12.6628 17.0419 14.1863" stroke={`${strokeColor ? strokeColor : '#585B63'}`}
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1153_1768">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default Give
