import { Button } from 'mastodon/components/button';
import { FormattedMessage } from 'react-intl';
import facebookIcon from '../../../../images/landing/facebook-icon.svg';
import twitterIcon from '../../../../images/landing/twitter-icon.svg';
import dribbleIcon from '../../../../images/landing/dribble.icon.svg';
import instagramIcon from '../../../../images/landing/instagram-icon.svg';
import { Link } from 'react-router-dom';

const footer_menu_items = [
  {
    title: 'Home',
    uri: '#',
  },
  {
    title: 'About us',
    uri: '#',
  },
  {
    title: 'Shop',
    uri: '#',
  },
  {
    title: 'Donate',
    uri: '#',
  },
  {
    title: 'Contact us',
    uri: '#',
  },
  {
    title: 'Live stream',
    uri: '#',
  },
  {
    title: 'Community',
    uri: '#',
  },
];

interface Props {}
export const Footer: React.FC<Props> = () => {
  return (
    <div className='landing-footer'>
      <div className='landing-footer-section'>
        <div className='landing-footer-logo'>
          <h4>K21CO</h4>
          <p>Bringing the Gospel to every community</p>
        </div>
        <div className='landing-footer-address'>
          <h3>Address</h3>
          <p>5075 Barthold Rd, Denton, TX 76207</p>
        </div>
      </div>
      <hr />

      <div className='landing-footer-section'>
        <ul className='landng-footer-menu'>
          {footer_menu_items.map((footer_menu_item) => (
            <li>
              <Link to={footer_menu_item.uri}>{footer_menu_item.title}</Link>
            </li>
          ))}
        </ul>
      </div>
      <hr id='landing-footer-hr'/>
      <div className='landing-footer-section'>
        <div className='landing-footer-terms'>
          <div>Copyright 2024</div>
          <div>Privacy policy</div>
          <div>Terms and conditions</div>
        </div>
        <div className='landing-footer-icons'>
          <img src={facebookIcon} alt='Facebook icon' />
          <img src={twitterIcon} alt='Twitter icon' />
          <img src={dribbleIcon} alt='Dribble icon' />
          <img src={instagramIcon} alt='Instagram icon' />
        </div>
      </div>
    </div>
  );
};
